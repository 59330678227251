export default {
  background: 'white',
  statusMap: {
    selected: 'rgb(255, 252, 127)',
    unselected: 'white',
    '1P': '#1a0500',
    '2m': '#4d0f00',
    '2M': '#801a00',
    '3m': '#cc2900',
    '3M': '#e62e00',
    '4P': '#ff3300',
    '4A': '#ff5c33',
    '5P': '#ff704d',
    '6m': '#ff9980',
    '6M': '#ffb199',
    '7m': '#ffd8cc',
    '7M': '#ffebe6',
  },
  octaveMap: {
    2: 'rgb(95, 190, 244)',
    3: 'rgb(135, 219, 244)',
    4: 'rgb(205, 240, 247)',
    5: 'rgb(242, 246, 247)',
  },
  fontSize: 12,
  dimensions: {
    openWidth: 10,
    nutWidth: 0.75,
    stringHeight: 30,
  },
  skins: {
    strings: {
      highlightSize: 85,
      highlightBorder: '1px solid gray',
    },
  },
}
